import React from "react";
import { Link } from "gatsby";
import { HandCraftedJourneysStyles } from "./styles";
import { Button } from "../Button";
import { Overlay } from "../Overlay";
import { GatsbySanityImage } from "../GatsbySanityImage";
import { SectionContainer } from "../SectionContainer";

export const HandCraftedJourney = ({ content }) => {
  return (
    <HandCraftedJourneysStyles>
      <Overlay className="parallax-overlay" bgColor="#fdf7ed" />
      <SectionContainer>
        <div className="parallax-main-wrapper">
          <div className="header-text">
            <p className="subtitle">only the best</p>
            <h2>hand-crafted journeys</h2>
          </div>

          <div className="parallax-inner-wrapper">
            <p className="description">
              Looking for an unforgettable luxury holiday experience? Look no
              further than our experts at Boundless Maldives, to curate a unique
              and personalized holiday experience tailored specifically to your
              needs. Whether you're looking for a private island escape or a
              luxury travel experience on a private jet, Boundless Maldives will
              exceed your expectations.Call us today to kick off your next
              adventure.
            </p>
            <ul>
              {content?.length &&
                content.map(({ title, image, description }) => (
                  <li key={title}>
                    <div className="image-container">
                      <GatsbySanityImage
                        gatsbyImage={image?.asset?.gatsbyImageData}
                      />
                    </div>
                    <div className="text-container">
                      <h3>{title}</h3>

                      <p className="itemDesc">{description} </p>
                    </div>
                  </li>
                ))}
            </ul>
            <Link to="/enquire" className="button-wrapper">
              <Button className="boundless-btn">Enquire</Button>
            </Link>
          </div>
        </div>
      </SectionContainer>
    </HandCraftedJourneysStyles>
  );
};
