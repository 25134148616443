import React from "react";
import { graphql, navigate } from "gatsby";
import SanityMuxPlayer from "sanity-mux-player";
import Image from "gatsby-plugin-sanity-image";
import Container from "../components/container";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import PromoSection from "../components/Homepage/PromoSection";
import AboutUs from "../components/Homepage/AboutUs";
import Journey from "../components/Homepage/Journey";

import Faq from "../components/Homepage/Faq";
import TailorMade from "../components/Homepage/TailorMade";
import { ContactUs } from "../components/Homepage/ContactUs";
import { HandCraftedJourney, Magazine } from "../components/Homepage";
import { HeroStyles } from "../components/Homepage/styles";
import { getResortUrl, getVillaUrl } from "../lib/helpers";
import WhyBoundlessSection from "../components/Homepage/WhyBoundlessSection";
import NewsletterSection from "../components/Homepage/NewsletterSection";
import Search from "../components/Search";
// import { Button } from "../components/Button";
import {
  FixedBackgroundImage,
  // Overlay,
  StickyNavBar,
  PricingProvider,
} from "../components";
import { isIOSDevice } from "../lib/helpers";
import { useRef } from "react";
import { useEffect } from "react";
import { useNavBar } from "../hooks";
import { RESORT, GLOBAL, SIMPLE_MAIN_NAVBAR } from "../constants";
export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
      url
    }
    colorType
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      description
      # videoURL
      video {
        asset {
          _key
          _type
          assetId
          filename
          playbackId
          status
          thumbTime
        }
      }

      handCraftedJourneys {
        title
        description
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
          alt
        }
      }
      mobileHeroImage {
        ...SanityImage
        alt
      }
      promoImageWeb {
        ...SanityImage
        alt
      }
      secondImage {
        ...SanityImage
        alt
      }
      aboutUs {
        title
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
          alt
        }
        _rawDescription(resolveReferences: { maxDepth: 10 })
      }
      whyBoundlessImage {
        ...SanityImage
        alt
      }

      faq {
        name
        description
        faqQuestionsAnswers {
          # _id
          answer
          question
        }
      }
      newsLetterTitle
      newsLetterBackground {
        asset {
          gatsbyImageData(placeholder: BLURRED, aspectRatio: 1.77)
        }
        alt
      }

      contactUs {
        address
        email
        phoneOne
        contactPeople {
          name
          image {
            ...SanityImage
            alt
          }
        }
        hours {
          days
          hours
        }
        businessHoursDescription
      }
    }

    collections: allSanityCollectionPage(limit: 5) {
      edges {
        node {
          CollectionPageName

          image {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }

            alt
          }
          thumbImage {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
            alt
          }
          slug {
            current
          }
        }
      }
    }
    magazinePosts: allSanityPost(limit: 3) {
      nodes {
        _rawExcerpt
        title
        mainImage {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
          # ...SanityImage
          alt
        }
        publishedAt
        slug {
          current
        }
      }
    }

    resorts: allSanityResort(
      sort: { fields: [name], order: DESC }
      filter: { active: { eq: true } }
    ) {
      nodes {
        shortName
        name
        image {
          asset {
            gatsbyImageData(width: 100, height: 100, placeholder: BLURRED)
          }
          alt
        }

        villas {
          name
          uniqueCode
        }
      }
    }
    villas: allSanityVilla(filter: { active: { eq: true } }) {
      nodes {
        name
        uniqueCode
        resort {
          shortName
          name
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data } = props;
  const { collections } = data;
  const site = (data || {}).site;
  let resorts = (data || {}).resorts;
  const villas = (data || {}).villas;
  const magazinePosts = (data || {}).magazinePosts;
  const { setHeroRef, setPageName, resetValues } = useNavBar();

  const heroRef = useRef();
  useEffect(() => {
    setHeroRef(heroRef);
    setPageName(SIMPLE_MAIN_NAVBAR);
  }, [heroRef?.current]);

  useEffect(() => {
    // dispatch({ type: PRICING_MODE, payload: { pricingMode: GLOBAL } });
    return () => {
      resetValues();
    };
  }, []);

  const onSearchInputChange = (input) => {
    let url = input
      ? input?.type === RESORT
        ? getResortUrl({ shortName: input?.value })
        : getVillaUrl({
            name: input.value,
            resortName: input.resortName,
          })
      : "";
    navigate(url);
  };

  // if (!site) {
  //   throw new Error(
  //     'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
  //   );
  // }

  return (
    <Layout {...props}>
      <SEO
        title={site?.title}
        description={site?.description}
        keywords={site?.keywords}
      />
      <Container>
        <HeroStyles ref={heroRef}>
          {site?.video?.asset ? (
            <SanityMuxPlayer
              assetDocument={site.video.asset}
              autoload={true}
              autoplay={true}
              className="video"
              height={"100vh"}
              loop={true}
              muted={true}
              showControls={false}
              style={{}}
              width={"100vh"}
            />
          ) : (
            site?.mobileHeroImage?.asset && (
              <Image {...site.mobileHeroImage} alt={site.mobileHeroImage.alt} />
            )
          )}
          <h1 className="disappear-on-scroll">Luxury experience curators</h1>
        </HeroStyles>
        <div className="page-content">
          <Search
            className="homepage-search"
            placeholder="Where would you like to go?"
            resorts={resorts.nodes}
            villas={villas.nodes}
            onChange={onSearchInputChange}
          />
          <Journey collections={collections} />
          <HandCraftedJourney content={site?.handCraftedJourneys} />
          <PromoSection image={site?.promoImageWeb} />
          <AboutUs aboutUs={site?.aboutUs} />
          <WhyBoundlessSection whyBoundlessImage={site?.whyBoundlessImage} />
          <Magazine content={magazinePosts.nodes} />
          <TailorMade />
          <div className="second-image">
            <FixedBackgroundImage
              bgImage={site?.secondImage?.asset?.url}
              isIos={isIOSDevice()}
            />
          </div>
          {site?.faq?.length && <Faq path="/" faq={site.faq[0]} />}
          <NewsletterSection site={site} isIos={isIOSDevice()} />
          <ContactUs contactUs={site?.contactUs} />
        </div>
      </Container>
      <PricingProvider>
        <StickyNavBar pricingMode={GLOBAL} />
      </PricingProvider>
    </Layout>
  );
};

export default IndexPage;
