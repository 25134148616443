import React from "react";
import Image from "gatsby-plugin-sanity-image";
import styled from "styled-components";
import { device } from "../../styles/deviceSizes";
import { FixedBackgroundImage } from "../Parallax";
import { GatsbySanityImage } from "../GatsbySanityImage";
export const NewsLetterStyles = styled.div`
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  .gatsby-image-wrapper {
    @media ${device.laptopL} {
      height: 50rem;
    }

    @media ${device.tablet} {
      height: 50rem;
    }

    @media ${device.mobileXL} {
      height: 30rem;
    }
  }
`;

const NewsletterSection = ({ site, isIos }) => {
  return (
    <NewsLetterStyles>
      {site.newsLetterBackground && site.newsLetterBackground.asset && (
        <GatsbySanityImage
          gatsbyImage={site?.newsLetterBackground?.asset?.gatsbyImageData}
          alt={site.newsLetterBackground?.alt}
        />
      )}
    </NewsLetterStyles>
  );
};

export default NewsletterSection;
