import React from "react";
import { Link } from "gatsby";
import { Button } from "../Button";
import PortableText from "../Ui/portableText";
import { Overlay } from "../Overlay";
import { GatsbySanityImage } from "../GatsbySanityImage";
import { SectionContainer } from "../SectionContainer";
import { MagazineStyles } from "./styles";
import { getBlogUrl } from "../../lib/helpers";

export const Magazine = ({ content }) => {
  return (
    <MagazineStyles>
      <Overlay className="parallax-overlay" bgColor="#fdf7ed" />
      <SectionContainer>
        <div className="content">
          <div className="header">
            <h2>Magazine</h2>
            <p className="subtitle">Inspiration</p>
          </div>
          <ul>
            {content.map(
              ({ title, _rawExcerpt, mainImage, publishedAt, slug }) => (
                <li key={title}>
                  <Link to={getBlogUrl(publishedAt, slug.current)}>
                    <div className="image-container">
                      {mainImage && mainImage.asset && (
                        <GatsbySanityImage
                          gatsbyImage={mainImage?.asset?.gatsbyImageData}
                          alt={mainImage.alt}
                        />
                      )}
                    </div>
                    <h3>{title}</h3>
                    {_rawExcerpt && <PortableText blocks={_rawExcerpt} />}
                  </Link>
                </li>
              )
            )}
          </ul>

          <Link to="/archive" className="button-wrapper">
            <Button>View More...</Button>
          </Link>
        </div>
      </SectionContainer>
    </MagazineStyles>
  );
};
