import React from "react";
import { FixedBackgroundImage } from "../Parallax";
import { isIOSDevice } from "../../lib/helpers";
import styled from "styled-components";
import { device } from "../../styles/deviceSizes";
import { Overlay } from "../Overlay";

export const PromoSectionStyles = styled.div`
  position: relative;
  height: 60rem;
  color: #fff !important;

  @media ${device.laptopL} {
    margin: 0;
  }

  @media ${device.tabletL} {
    margin: 0;
  }

  @media ${device.tablet} {
    height: 50rem;
  }

  @media ${device.mobileXL} {
    height: 30rem;
  }

  img {
    height: 100%;
    width: 100%;

    object-fit: contain;
    @media ${device.laptopM} {
      object-position: right;
    }
  }

  h2 {
    z-index: 100;
    width: 45rem;
    color: #fff;
    text-align: center;
    letter-spacing: 1rem;
    text-transform: lowercase;
    font-weight: bold;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    @media ${device.onlyMobileSm} {
      width: 100%;
    }
  }

  p {
    z-index: 99;
    width: 45rem;
    margin-top: 5rem;
    font-weight: bold;
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    @media ${device.onlyMobileSm} {
      width: 100%;
      text-align: center;
    }
  }

  .promo-inner-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;

const PromoSection = ({ image }) => {
  return (
    <PromoSectionStyles>
      <Overlay opacity={0.1} zIndex={1} />
      <FixedBackgroundImage bgImage={image?.asset?.url} isIos={isIOSDevice()}>
        <div className="promo-inner-content">
          <h2 className="parallax__layer--back">
            we specialise in the maldives
          </h2>
          <div>
            <p className="parallax__layer--back">
              hand-picked portfolio of the world’s most luxurious resorts and
              Villas in the most stunning locations.
            </p>
          </div>
        </div>
      </FixedBackgroundImage>
    </PromoSectionStyles>
  );
};

export default PromoSection;
