import React from "react";
import PortableText from "../Ui/portableText";
import styled from "styled-components";
import { device } from "../../styles/deviceSizes";
import { GatsbySanityImage } from "../GatsbySanityImage";
import { SectionContainer } from "../SectionContainer";
const AboutUsSectionStyles = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background: white;
  z-index: 1;
  .section-container {
    margin-top: 7rem;
    margin-bottom: 10rem;
    @media ${device.onlyMobileSm} {
      margin-top: 5rem;
      margin-bottom: 5rem;
    }
  }

  .about-us {
    &__image-container {
      width: 50rem;
      height: 50rem;
      height: auto;
      margin-right: 4rem;

      @media ${device.tabletL} {
        display: none;
      }
    }
    &__text {
      h2 {
        text-transform: uppercase;
        margin-bottom: 2rem;
        text-align: left !important;
      }

      p {
        max-width: 80rem;
        margin: 2rem 0;
        @media ${device.onlyMobileSm} {
          text-align: justify;
        }
      }
    }
  }
  .section-container {
    flex-flow: row;
  }
`;

const AboutUs = ({ aboutUs }) => {
  return (
    <AboutUsSectionStyles>
      <SectionContainer className="section-container">
        <div className="about-us__image-container">
          {aboutUs?.image?.asset && (
            <GatsbySanityImage
              gatsbyImage={aboutUs?.image?.asset?.gatsbyImageData}
              alt={aboutUs.image.alt}
            />
          )}
        </div>
        <div className="about-us__text">
          <h2>{aboutUs.title}</h2>
          {aboutUs._rawDescription && (
            <PortableText
              className="about-us__text-block"
              blocks={aboutUs._rawDescription}
            />
          )}
        </div>
      </SectionContainer>
    </AboutUsSectionStyles>
  );
};

export default AboutUs;
