import React from "react";
import { Overlay } from "../Overlay";
import { navigate } from "gatsby";
import { JourneyStyles } from "./elements";
import { GatsbySanityImage } from "../GatsbySanityImage";
import { SectionContainer } from "../SectionContainer";

const Journey = ({ collections }) => {
  return (
    <JourneyStyles>
      <SectionContainer>
        <h2>Start your journey</h2>
        <div className="images">
          {collections.edges
            .sort((a, b) => a.node.rank - b.node.rank)
            .map(({ node }) => {
              const collectionImage = node?.thumbImage
                ? node?.thumbImage
                : node?.image;

              return (
                <div
                  className="clickable"
                  onClick={() => {
                    navigate(`/collections/${node.slug.current}/`);
                  }}
                  key={node.name}
                >
                  <div
                    className="card-text-wrapper"
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate(`/collections/${node?.slug?.current}/`);
                    }}
                  >
                    {node.CollectionPageName}
                  </div>
                  <Overlay className="overlay" />
                  {/* <div style={{ position: "relative" }}> */}
                  <GatsbySanityImage
                    gatsbyImage={collectionImage?.asset?.gatsbyImageData}
                    // assetId={collectionImage?.asset?._id}
                  />
                </div>
              );
            })}
        </div>
      </SectionContainer>
    </JourneyStyles>
  );
};

export default Journey;
